<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Laporan Kontrak</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <!-- BEGIN:Petunjuk -->
              <iq-card
                class="
                  iq-card-block iq-bg-info
                  text-dark
                  iq-card-stretch iq-card-height
                  p-3
                "
              >
                <div>
                  <span class="font-weight-bold"
                    >PETUNJUK PENGGUNAAN FASILITAS LAPORAN KONTRAK :</span
                  >
                  <div>
                    1. Untuk mencari <b>semua data</b> laporan kontrak :
                    <br />
                    <p class="px-3">
                      - Klik checkbox "Semua (Tahun & UPBJJ/Pusat)" <br />
                      - Klik tombol "Cari" Untuk melihat data laporan kontrak
                    </p>
                  </div>
                  <div>
                    2. Untuk mencari data laporan berdasarkan
                    <b>Tahun & UPBJJ</b> :
                    <br />
                    <p class="px-3">
                      - Pilih "UPBJJ" <br />
                      - Masukan "Tahun" yang ingin dicari <br />
                      - Klik tombol "Cari" Untuk melihat data laporan kontrak
                    </p>
                  </div>
                  <div>
                    3. Untuk mencari data laporan berdasarkan <b>Tahun</b> :
                    <br />
                    <p class="px-3">
                      - Masukan "Tahun" yang ingin dicari <br />
                      - Klik tombol "Cari" Untuk melihat data laporan kontrak
                    </p>
                  </div>
                  <div>
                    4. Untuk mencari data laporan berdasarkan <b>UPBJJ</b> :
                    <br />
                    <p class="px-3">
                      - Pilih "UPBJJ" <br />
                      - Klik tombol "Cari" Untuk melihat data laporan kontrak
                    </p>
                  </div>
                </div>
              </iq-card>
              <!-- ./END:Petunjuk -->
              <!-- BEGIN:Form Pencarian -->
              <div
                class="
                  card
                  iq-card-block
                  bg-light
                  text-dark
                  iq-card-stretch iq-card-height
                "
                style="z-index: 1"
              >
                <div class="card-header bg-primary text-center">
                  <h5 class="font-weight-bold text-white">Form Pencarian</h5>
                </div>
                <div class="card-body">
                  <b-form class="px-4">
                    <b-form-group>
                      <b-form-checkbox
                        id="semuaData"
                        v-model="form.semuaData"
                        required
                        @click="setSemuaData($event)"
                        @change="form.tahun = null"
                        :disabled="form.upbjj != null || form.tahun != null"
                        >Semua (Tahun & UPBJJ/Pusat)</b-form-checkbox
                      >
                    </b-form-group>
                    <div class="font-weight-bold pb-3">atau</div>
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <input-datalists
                            id="upbjj"
                            class="w-full text-sm"
                            placeholder="Pilih UPBJJ"
                            v-model="form.upbjj"
                            label="dataUpbjj"
                            required
                            :options="
                              (listDataUpbjj &&
                                listDataUpbjj.map(data => {
                                  return {
                                    dataUpbjj:
                                      data.kodeJenisPks + ` | ` + data.namaPks,
                                    kodeUpbjj: data.kodeJenisPks,
                                    idUpbjj: parseInt(data.kodeJenisPks)
                                  }
                                })) ||
                                []
                            "
                            :disabled="form.semuaData == 1"
                          >
                            <template #header>
                              <label class="block">UPBJJ / Pusat:</label>
                            </template>
                          </input-datalists>
                          <div class="text-secondary">
                            ** Jika <b>UPBJJ / Pusat tidak dipilih</b> maka akan
                            tampil data semua UPBJJ / Pusat berdasarkan Tahun
                            yang diisi
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="Tahun:" label-for="tahun">
                          <input-primary
                            id="tahun"
                            v-model="form.tahun"
                            placeholder="Masukkan tahun yang ingin dicari"
                            required
                            type="number"
                            max-length="4"
                            :disabled="form.semuaData == 1"
                          />
                          <div class="text-secondary">
                            ** Jika <b>Tahun tidak diisi</b> maka akan tampil
                            data semua tahun berdasarkan UPBJJ yang dipilih
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col>
                        <b-btn
                          class="btn-block"
                          variant="primary"
                          @click="searchData"
                          :disabled="!isValidForm"
                          >Cari</b-btn
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </div>
              <!-- ./END:Form Pencarian -->
              <!-- BEGIN:Table -->
              <div class="card mt-4">
                <div class="card-header">
                  <h5 class="font-weight-bold">Daftar Laporan Kontrak</h5>
                </div>
                <div class="card-body">
                  <div class="align-items-center">
                    <b-row>
                      <b-col>
                        <b-form-input
                          class="w-25"
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Cari Data"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-table
                      responsive
                      bordered
                      :fields="thead"
                      :items="listData"
                      class="mt-2"
                      :busy="isLoading"
                      :filter="filter"
                      :per-page="perPage"
                      :current-page="currentPage"
                      @filtered="onFiltered"
                      show-empty
                    >
                      <template v-slot:cell(aksi)>
                        <div class="d-flex">
                          <router-link
                            class="btn iq-bg-info rounded-sm mx-1"
                            v-b-tooltip="'Lihat Detail'"
                            size="sm"
                            role="button"
                            :to="{ name: 'kerjasama.pks.detail' }"
                            ><i class="ri-search-line m-0"></i
                          ></router-link>
                          <b-button
                            class="mx-1"
                            variant=" iq-bg-success"
                            size="sm"
                            v-b-tooltip="'Lihat Dokumen Kerjasana'"
                            ><i class="ri-file-line m-0"></i
                          ></b-button>
                        </div>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <div
                          class="
                            bg-light
                            text-danger
                            font-weight-bold
                            mx-auto
                            text-center
                          "
                        >
                          Data tidak ditemukan
                        </div>
                      </template>
                    </b-table>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Per page:"
                          label-for="per-page-select"
                          label-size="sm"
                          class="d-flex"
                        >
                          <b-form-select
                            class="ml-2"
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-pagination
                          class="float-right"
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <!-- <div class="card-footer">
                  <b-row>
                    <b-col>
                      <b-btn class="btn-block" variant="danger"
                        >Unduh Excel XLS</b-btn
                      >
                    </b-col>
                    <b-col>
                      <b-btn class="btn-block" variant="secondary"
                        >Unduh Excel XLSX</b-btn
                      >
                    </b-col>
                    <b-col>
                      <b-btn class="btn-block" variant="success"
                        >Unduh CSV</b-btn
                      >
                    </b-col>
                  </b-row>
                </div> -->
              </div>
              <!-- ./END:Table -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
// import { Vue } from 'vue-property-decorator'
// import store from '@/store/index'
// import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'laporan.kontrak',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.semuaData || (this.form.tahun || this.form.upbjj) !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
    /**
     * Set the initial number of items
     */
    this.totalRows = this.listData.length
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Set checkbox semua tahun
     */
    setSemuaData(event) {
      event.target.checked === true
        ? (this.form.semuaData = 1)
        : (this.form.semuaData = 0)
    },
    /**
     * Get data UPBJJ
     */
    async getDataKategoriPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=jenisPks`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataUpbjj = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Search Data
     */
    searchData() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Init Data
     */
    async innitData() {
      /**
       * Get List Data
       */
      await Promise.all([this.getDataKategoriPks_()])
    }
  },
  data() {
    return {
      // Tabel Options
      isLoading: false,
      filter: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: '100' }],
      currentPage: 1,
      totalRows: 1,
      // Form
      form: {
        semuaData: null,
        tahun: null,
        upbjj: null
      },
      // Data UPBJJ
      listDataUpbjj: [],
      // Table
      thead: [
        { label: 'No', key: 'id', class: 'text-center' },
        { label: 'Kode KS', key: 'kode_ks', class: 'text-center' },
        { label: 'Nomor PKS UT', key: 'nomor_pks_ut', class: 'text-center' },
        {
          label: 'Nomor PKS Mitra',
          key: 'nomor_pks_mitra',
          class: 'text-center'
        },
        { label: 'Tanggal PKS', key: 'tanggal_pks', class: 'text-center' },
        {
          label: 'Tanggal Berlaku',
          key: 'tanggal_berlaku',
          class: 'text-center'
        },
        {
          label: 'Tanggal Berakhir',
          key: 'tanggal_berakhir',
          class: 'text-center'
        },
        { label: 'Kode Mitra', key: 'kode_mitra', class: 'text-center' },
        { label: 'Nama Mitra', key: 'nama_mitra', class: 'text-center' },
        { label: 'Nilai Kontrak', key: 'nilai_kontrak', class: 'text-center' },
        {
          label: 'Jumlah Pelunasan',
          key: 'jumlah_pelunasan',
          class: 'text-center'
        },
        {
          label: 'Tanggal Pelunasan',
          key: 'tanggal_pelunasan',
          class: 'text-center'
        },
        { label: 'Keterangan KS', key: 'keterangan', class: 'text-justify' },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      listData: [
        {
          id: 1,
          kode_ks: '000355',
          nomor_pks_ut: '19/UN31.WR.4/HK.01.00/2019',
          nomor_pks_mitra: '29/REGIONAL 5/BISRATKETLOG/TNSL/P/0119',
          tanggal_pks: '07-01-2019',
          tanggal_berlaku: '07-01-2019',
          tanggal_berakhir: '31-12-2019',
          kode_mitra: '00006',
          nama_mitra: 'PT.POS Indonesia',
          nilai_kontrak: '0',
          jumlah_pelunasan: null,
          tanggal_pelunasan: null,
          keterangan: 'Jasa Pengiriman Surat dan Paket'
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    return next()
    // const idUnitKs = store.state.authService.idUnitKs
    // if (MENU_ROLE.DAAK_DAN_KEUANGAN.includes(String(idUnitKs))) {
    //   return next()
    // } else {
    //   Vue.$toast.error('Anda tidak memiliki hak akses')
    //   return next({ name: 'sidebar.dashboard' })
    // }
  }
}
</script>
